@tailwind base;
@tailwind utilities;

.sidebar {
    @media (max-height: 450px) {
        @apply relative h-auto block;
    }
}

.background {
    @apply h-full w-full absolute top-0 left-0 opacity-20;
    background: linear-gradient(90deg, red, cyan);
    
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      mask-image: linear-gradient(to bottom, transparent, black);
      background: linear-gradient(90deg, hotpink, rebeccapurple); 
    }
}

.container {
    @apply px-6 max-w-5xl mx-auto;
}

* {
    @apply selection:bg-orange-500 selection:text-white;
}